import { Button, Spinner } from '@material-tailwind/react'
import { useEffect, useRef } from 'react'

import ChatMessage from './ChatMessage'
import { ChatHandler } from './chat.interface'
import { useClientConfig } from './useConfig'

const ChatMessages = (props: Pick<ChatHandler, 'messages' | 'isLoading' | 'reload' | 'stop' | 'append'>) => {
  const { starterQuestions } = useClientConfig()
  const messageLength = props.messages.length
  const lastMessage = props.messages[messageLength - 1]
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const isLastMessageFromAssistant = messageLength > 0 && lastMessage?.role !== 'user'
  const isPending = props.isLoading && !isLastMessageFromAssistant
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [props.messages, props.isLoading])
  return (
    <div id="ava-sidebar-content" className="text-gray-900 text-base mb-3 md:mb-6 relative">
      <div className="space-y-1 overflow-y-auto h-full">
        <div>
          {props.messages.map((m, i) => {
            const isLoadingMessage = i === messageLength - 1 && props.isLoading
            return <ChatMessage key={m.id} chatMessage={m} isLoading={isLoadingMessage} />
          })}
          {isPending && <Spinner className="mx-auto h-5 w-5 text-white min-h-[200px]" />}
          <div ref={messagesEndRef} />
        </div>
        {!messageLength && starterQuestions?.length && props.append && (
          <div className="absolute bottom-6 left-0 w-full">
            <div className="grid grid-cols-2 gap-2 mx-20">
              {starterQuestions.map((question, i) => (
                <Button key={i} onClick={() => props.append!({ role: 'user', content: question })}>
                  {question}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default ChatMessages

import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from '@material-tailwind/react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import { useMemo, useState } from 'react'

import { SourceData } from '.'

export const ChatTabSources = ({ data }: { data: SourceData }) => {
  const t = useTranslations('AskAva')
  const [activeTab, setActiveTab] = useState<string>('tab-0')

  const renderTabs = () => {
    return data.nodes.map((node, index) => (
      <Tab
        key={node.id}
        value={`tab-${index + node.id}`}
        onClick={() => setActiveTab(`tab-${index + node.id}`)}
        className={`min-w-6 rounded-full transition-colors 
        ${activeTab === `tab-${index + node.id}` ? 'bg-brand-purple text-white rounded-full' : 'bg-gray-300 text-black rounded-full'}`}
      >
        <div className="min-w-4">{index + 1}</div>
      </Tab>
    ))
  }

  const SCORE_THRESHOLD = 0.3

  type NodeInfoType = {
    id: string
    url?: string
    metadata: {
      link?: string
      title?: string
      author?: string
    }
    content?: string
  }

  const sources: NodeInfoType[] = useMemo(() => {
    const nodesByPath: { [path: string]: NodeInfoType } = {}

    data.nodes
      ?.filter((node) => (node.score ?? 1) > SCORE_THRESHOLD)
      ?.sort((a, b) => (b.score ?? 1) - (a.score ?? 1))
      ?.forEach((node) => {
        const nodeInfo = {
          id: node.id,
          url: node.url,
          metadata: node.metadata,
          content: node.text,
        }
        const key = nodeInfo.url ?? nodeInfo.id
        if (!nodesByPath[key]) {
          nodesByPath[key] = nodeInfo
        }
      })

    return Object.values(nodesByPath)
  }, [data.nodes])

  const renderTabPanels = () => {
    return sources.map((nodeInfo: NodeInfoType, index: number) => (
      <TabPanel key={nodeInfo?.id} value={`tab-${index + nodeInfo?.id}`}>
        <div className="flex flex-col gap-1">
          <Link
            className="text-blue-600 text-wrap underline font-medium hover:text-blue-800 visited:text-purple-600 text-base"
            href={nodeInfo?.metadata?.link ?? ''}
            target="_blank"
          >
            {nodeInfo.metadata.title}
          </Link>
          <p className="text-gray-900 text-base text-wrap">
            <strong>{t('author')}:</strong> {nodeInfo?.metadata?.author}
          </p>
        </div>
      </TabPanel>
    ))
  }

  return (
    <div className="w-full min-w-full max-w-full bg-brand-tan tab-container">
      <Tabs value={activeTab} className="bg-brand-tan">
        <div className="flex items-center bg-brand-tan">
          <TabsHeader
            className="px-0 bg-brand-tan"
            indicatorProps={{
              className: 'bg-brand-purple rounded-full',
            }}
          >
            <div className="flex items-center justify-start gap-2 bg-brand-tan">
              <span className="text-gray-900 text-base font-bold">{t('sources')}: </span>
              {renderTabs()}
            </div>
          </TabsHeader>
        </div>

        <TabsBody>{renderTabPanels()}</TabsBody>
      </Tabs>
    </div>
  )
}

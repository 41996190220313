'use client'

import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'
import { useEffect, useMemo, useState } from 'react'

import ChatAvatar from '../Chat/ChatAvatar'

interface Props {
  onLetsStartClick: () => void
}
const AutowelcomeMessage = ({ onLetsStartClick }: Props) => {
  const t = useTranslations('AutowelcomeMessage')

  const { data } = useSession()

  const speed = 5

  const text = useMemo(() => {
    let defaultText = t('defaultMessage').replace('.', '.\n')

    if (data && data.user.pathways?.includes('Financial Aid'))
      defaultText = defaultText + t('financialAid').replace('.', '.\n')

    defaultText = defaultText + '\n{{clickable}}'
    return defaultText
  }, [data, t])

  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)

  const clickablePlaceholder = '{{clickable}}'
  const clickableText = t('letsGetStarted')

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText(displayedText + text.charAt(index))
        setIndex(index + 1)
      }, speed)

      return () => clearTimeout(timeout)
    }
  }, [index, text, speed, displayedText])
  const lines = displayedText.split('\n')

  return (
    <div id="ava-sidebar-content" className="p-2 md:p-4">
      <div className={`flex items-start gap-4 pr-5 pt-2`}>
        <ChatAvatar role={'assistant'} />
        <div className={`group flex flex-1 flex-col justify-between gap-2 pt-0`}>
          {lines.map((line, idx) => {
            const parts = line.split(clickablePlaceholder)
            return (
              <div key={idx}>
                {' '}
                {parts[0]}
                {parts.length > 1 && (
                  <a href="#" className="text-blue-600 underline" onClick={() => onLetsStartClick()}>
                    {clickableText}
                  </a>
                )}
                {parts[1]}
                {idx < lines.length - 1 && <br />}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AutowelcomeMessage

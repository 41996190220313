'use client'

import React, { createContext, useCallback, useContext, useState } from 'react'

type AvaSidebarContextType = {
  isOpen: boolean
  shouldShowWelcomeMessage: boolean | undefined
  openSidebar: () => void
  closeSidebar: () => void
  toggleSidebar: (showWelcomeMessage?: boolean) => void
}

const AvaSidebarContext = createContext<AvaSidebarContextType | undefined>(undefined)

export const AvaSidebarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [shouldShowWelcomeMessage, setShouldShowWelcomeMessage] = useState<boolean | undefined>(undefined)

  const openSidebar = useCallback(() => setIsOpen(true), [])
  const closeSidebar = useCallback(() => setIsOpen(false), [])
  const toggleSidebar = useCallback((showWelcomeMessage?: boolean) => {
    setIsOpen(true)
    setShouldShowWelcomeMessage(showWelcomeMessage)
  }, [])

  return (
    <AvaSidebarContext.Provider value={{ isOpen, shouldShowWelcomeMessage, openSidebar, closeSidebar, toggleSidebar }}>
      {children}
    </AvaSidebarContext.Provider>
  )
}

export const useAvaSidebar = () => {
  const context = useContext(AvaSidebarContext)
  if (context === undefined) {
    throw new Error('useAvaSidebar must be used within an AvaSidebarProvider')
  }
  return context
}

'use client'

import {
  ArrowPathIcon,
  ClipboardIcon,
  DocumentDuplicateIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline'
import {
  HandThumbDownIcon as HandThumbDownIconSolid,
  HandThumbUpIcon as HandThumbUpIconSolid,
} from '@heroicons/react/24/solid'
import { Button, Tooltip } from '@material-tailwind/react'
import { Message } from 'ai'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

import ChatMessages from '../Chat/ChatMessages'

interface Props {
  chatId: string
  messages: Message[]
  isLoading: boolean
  reload?: (() => void) | undefined
  stop?: (() => void) | undefined
  append?:
    | ((
        message: Message | Omit<Message, 'id'>,
        ops?: {
          data: any
        }
      ) => Promise<string | null | undefined>)
    | undefined
}
const AvaConversationState = ({ messages, isLoading, reload, stop, append, chatId }: Props) => {
  const [showFeedback, setShowFeedback] = useState(false)
  const [feedbackType, setFeedbackType] = useState('')
  const [feedbackComment, setFeedbackComment] = useState('')
  const [copySuccess, setCopySuccess] = useState(false)
  const [activeFeedback, setActiveFeedback] = useState('')
  const t = useTranslations('AskAva')

  const handleFeedbackClick = (type: string) => {
    setFeedbackType(type)
    setShowFeedback(true)
    setActiveFeedback(type)
  }

  const handleFeedbackSubmit = () => {
    // Handle feedback submission logic here
    setShowFeedback(false)
    setFeedbackComment('')
    setActiveFeedback('')

    fetch(`/api/chatbot/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        chatbotSessionId: chatId,
        feedback: feedbackType,
        comments: feedbackComment,
      }),
    })
  }

  const handleCopyToClipboard = () => {
    const textToCopy = messages[messages.length - 1].content

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess(true)
        setTimeout(() => setCopySuccess(false), 2000) // Hide success message after 2 seconds
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  const handleCopyAllToClipboard = () => {
    const textToCopy = messages
      .map((msg) => {
        return `${msg.role}: ${msg.content}`
      })
      .join(';')

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopySuccess(true)
        setTimeout(() => setCopySuccess(false), 2000) // Hide success message after 2 seconds
      })
      .catch((err) => console.error('Failed to copy text: ', err))
  }

  return (
    <>
      <ChatMessages messages={messages} isLoading={isLoading} reload={reload} stop={stop} append={append} />
      {!isLoading && (
        <div className="flex space-x-2 mt-4 ml-10">
          <button
            className="relative group hover:shadow-none shadow-none rounded-full"
            onClick={() => handleFeedbackClick('positive')}
          >
            <Tooltip content={t('goodResponse')} position="top" className="py-2">
              {activeFeedback === 'thumbs-up' ? (
                <HandThumbUpIconSolid className="w-6 h-6 text-gray-500 " />
              ) : (
                <HandThumbUpIcon className="w-6 h-6 text-gray-500 " />
              )}
            </Tooltip>

            {/* <span
              className="absolute bottom-full mb-1 w-max p-3 bg-gray-900
                    text-sm text-white rounded hidden group-hover:block"
            >
              
            </span> */}
          </button>
          <button className="relative group" onClick={() => handleFeedbackClick('negative')}>
            <Tooltip content={t('badResponse')} position="top" className="py-2">
              {activeFeedback === 'thumbs-down' ? (
                <HandThumbDownIconSolid className="w-6 h-6 text-gray-500 " />
              ) : (
                <HandThumbDownIcon className="w-6 h-6 text-gray-500 " />
              )}
            </Tooltip>
          </button>
          <button className="relative group" onClick={() => handleCopyAllToClipboard()}>
            <Tooltip content={t('copyAll')} position="top" className="py-2">
              <DocumentDuplicateIcon className="w-6 h-6 text-gray-500 " />
            </Tooltip>
          </button>
          <button className="relative group" onClick={() => handleCopyToClipboard()}>
            <Tooltip content={t('copy')} position="top" className="py-2">
              <ClipboardIcon className="w-6 h-6 text-gray-500 " />
            </Tooltip>
          </button>
          <button className="relative group" onClick={() => reload && reload()}>
            <Tooltip content={t('regenerate')} position="top" className="py-2">
              <ArrowPathIcon className="w-6 h-6 text-gray-500 " />
            </Tooltip>
          </button>
        </div>
      )}

      {/* Copy success message */}
      {copySuccess && <div className="mt-2 text-green-600 text-sm">{t('avaChatCopied')}</div>}

      {/* Feedback textbox */}
      {showFeedback && (
        <div className="mt-4 ml-10">
          <p className="text-sm mb-2">{t('feedbackQuestion')}</p>
          <textarea
            className="w-full md:w-3/4 p-2 border min-h-24 border-gray-400 rounded-lg bg-brand-tan
                     focus:border-gray-400 focus:shadow-none text-sm"
            placeholder={t('feedbackPlaceholder')}
            value={feedbackComment}
            onChange={(e) => setFeedbackComment(e.target.value)}
          />
          <div>
            <Button
              className="py-3 px-4 bg-white text-gray-900 border-gray-900 rounded-lg text-sm font-bold"
              onClick={handleFeedbackSubmit}
            >
              {t('submit')}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default AvaConversationState

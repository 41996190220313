import { forwardRef, useCallback, useRef } from 'react'

import Checkbox from '@/components/Forms/Checkbox'

interface Props {
  id: string
  index: number
  label: string
  components: { label: string; value: string }[]
  onChange: (event: { target: { value: string | string[]; name: string } }) => void
  name: string
  value: string
  error?: string
  removeError: () => void
}

const CheckboxGroup = forwardRef<HTMLDivElement, Props>(
  ({ id, index, components, label, onChange, name, value, error, removeError }, ref) => {
    const handleChange = useCallback(
      (option: { label: string; value: string }) => {
        onChange({ target: { value: option.value, name } })
      },
      [name, onChange]
    )

    const refs = useRef<(HTMLElement | null)[]>([])

    return (
      <div
        ref={ref}
        key={id}
        className={`w-full sm-custom:w-[40%] lg:!w-[27%] mb-5  ${index !== 0 && (index + 1) % 2 === 0 ? 'mr-0 ' : 'mr-[2%] lg:mr-0'} 
          ${index !== 0 && (index + 1) % 3 === 0 ? 'mr-0 ' : 'lg:mr-[7%]'} lg:mb-10 `}
      >
        <p className="text-lg">{label}</p>
        {error && <p className="text-xs text-red-500">{error}</p>}

        {components.map((checkboxItem, index) => (
          <Checkbox
            key={checkboxItem.label}
            value={checkboxItem.value}
            onChange={() => {
              removeError()
              handleChange(checkboxItem)
            }}
            checked={checkboxItem.value === value}
            ref={(el) => (refs.current[index] = el)}
          >
            {checkboxItem.label}
          </Checkbox>
        ))}
      </div>
    )
  }
)

CheckboxGroup.displayName = 'CheckboxGroup'

export default CheckboxGroup
